<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="require('@/assets/images/logos/logo.png')"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              La Formation Pro
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->
        <v-card-text v-if="!loading">
          <v-alert
            :color="alertStatus"
            text
            class="mb-0"
          >
            <div class="d-flex align-start">
              <v-icon :color="alertStatus">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{ alertTxt }}
                </p>
              </div>
            </div>
          </v-alert>
        </v-card-text>
        <div
          v-else
          class="text-center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>

        <v-card-actions class="d-flex justify-center">
          <a
            color="primary"
            class="mt-5"
            @click="$router.push({name:'calendar'})"
          >
            Retour à l'accueil
          </a>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAlertOutline } from '@mdi/js'

export default {
  data() {
    return {
      alertStatus: 'success',
      alertTxt: '',
      alert: '',
      token: '',
      loading: true,
    }
  },
  mounted() {
    this.token = this.$route.query.token
    this.verifyEmail()
  },
  setup() {
    return {
      icons: {
        mdiAlertOutline,
      },
    }
  },
  methods: {
    verifyEmail() {
      this.$http.post(`${process.env.VUE_APP_API_URL}/users-permissions/verify-email/`, { confirmationToken: this.token })
        .then(res => {
          if (res.status === 200) {
            this.alertStatus = 'success'
            this.alertTxt = 'Votre adresse e-mail a été vérifiée avec succès !'
            console.log(res)
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.alertStatus = 'warning'
            this.alertTxt = 'Aucun e-mail ne correspond à ce jeton :('
          } else {
            this.alertStatus = 'error'
            this.alertTxt = 'Erreur lors de la vérification de l\'e-mail'
          }
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
